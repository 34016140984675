<template>
    <div id="personal-info">
        <leftbar-component></leftbar-component>
        <div class="content">
            <topbar-component :items="items"></topbar-component>
            <h1>个人资料</h1>
            <v-form
                ref="form"
                class="form">
                <div class="top">
                    <div class="avatar">
                        <el-upload ref="headurl" action=""
                                   :auto-upload="false" :show-file-list="false"
                                   :on-change="(file, fileList) => {chooseUploadFile(file, fileList, 'headurl', false)}">
                            <img v-if="formData.headurl" :src="man.fast.getResourcePath(formData.headurl, 1)" title="点击更换头像">
                            <img v-else :src="require('../../assets/images/user-center/icon_user.png')" title="点击更换头像">
                        </el-upload>
                        <span>点击头像修改</span>
                    </div>
                    <div class="item">
                        <div class="label">用户姓名</div>
                        <div class="text">
                            <input
                                type="text"
                                v-model="formData.userContact"
                                @change="entryMsg($event)"
                                placeholder="请输入姓名"
                            />
                        </div>
                    </div>
                    <div class="item" v-if="formData.isTeacher">
                        <div class="label">教师等级</div>
                        <div class="text">{{ teacherLevel ? teacherLevel + '教师' : '无' }}</div>
                    </div>
                    <div class="item">
                        <div class="label">个人简介</div>
                        <div class="text">
                            <textarea
                                name="resume"
                                v-model="formData.resume"
                                maxlength="140"
                                @change="entryMsg($event)"
                                placeholder="请输入">
                            </textarea>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">手机号码</div>
                        <div class="text">
                            <input
                                type="tel"
                                name="userPhone"
                                v-model="formData.userPhone"
                                @change="entryMsg($event)"
                                placeholder="请输入手机号码"
                                maxlength="11"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">电子邮箱</div>
                        <div class="text">
                            <input
                                type="text"
                                name="userMail"
                                v-model="formData.userMail"
                                @change="entryMsg($event)"
                                placeholder="请输入邮箱"
                            />
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">所在省市</div>
                        <div class="text">
                            <el-cascader v-model="formData.provinceAndCity"
                                         :options="seloptsPrvinceAndCity"
                                         size="mini"
                                         style="width: 100%;"
                                         clearable>
                            </el-cascader>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">详细住址</div>
                        <div class="text">
                            <input
                                type="text"
                                name="userAdd"
                                v-model="formData.userAdd"
                                @change="entryMsg($event)"
                                placeholder="请输入详细地址"
                            />
                        </div>
                    </div>
                    <div class="item password">
                        <div class="label">账号密码</div>
                        <div class="text">
                            <input
                                type="password"
                                v-model="formData.userPass"
                                placeholder="请输入"
                                @change="entryMsg($event)"
                                minlength="6"
                                maxlength="24"
                            />
                        </div>
                    </div>
                </div>

                <div class="teacher" v-if="formData.isTeacher">
                    <div class="item">
                        <div class="label">老师头衔</div>
                        <div class="text">
                            <input
                                type="text"
                                name="title"
                                v-model="formData.title"
                                @change="entryMsg($event)"
                                placeholder="请输入"/>
                        </div>
                    </div>
                    <div class="item">
                        <div class="label">教师资质</div>
                        <div class="text">
                            <i class="icon-license"></i>
                            <span class="filename">{{ accessoryName ? accessoryName : formData.accessory }}</span>
                            <el-upload ref="accessory" action=""
                                       accept=".zip,.rar,.7z"
                                       :auto-upload="false" :show-file-list="false"
                                       :on-change="(file, fileList) => {chooseUploadFile(file, fileList, 'accessory', false)}"
                                       style="float: right;"
                                       class="uploadBtn">
                                <label>{{ formData.accessory ? '重新上传' : '上传文件' }}</label>
                            </el-upload>
                        </div>
                    </div>
                    <div class="tips">
                        <h2>申请成为老师须知：请将下列证明文件打包上传：</h2>
                        <p>1.身份证 (常见图片格式)</p>
                        <p>2.教师资格证或行业证书 (常见图片格式)</p>
                        <p>3.简历 (PDF或者WORD格式)</p>
                        <p>4.上课短视频 (3分钟内的MP4格式，10M以内)</p>
                    </div>
                </div>
                <div class="btn">
                    <button type="button" @click="submit">保存</button>
                </div>
            </v-form>
        </div>
    </div>
</template>

<script>
    import LeftbarComponent from './component/leftbarComponent'
    import TopbarComponent from './component/topbarComponent'
    import {urls, banbaoUrl} from '@/lib/common';
    import {provinceAndCityData} from 'element-china-area-data';

    export default {
        name: "PersonalInfo",
        components: {
            LeftbarComponent,
            TopbarComponent
        },
        data() {
            return {
                banbaoUrl,
                seloptsPrvinceAndCity: provinceAndCityData,
                items: [
                    {
                        text: '个人中心',
                        disabled: false,
                        href: '/userCenter',
                    }, {
                        text: '个人资料',
                        disabled: true,
                        href: '/personalInfo',
                    }
                ],
                formData: {
                    headurl: '',
                    userPointer: 0,
                    accessory: '',
                    provinceAndCity: [],
                },
                accessoryName: '',
                teacherLevel: '',
                token: ''
            }
        },
        created() {
            const token = this.$cookies.get('app.token', {domain: banbaoUrl, path: ''});
            if (token) this.token = token;
            if (sessionStorage.getItem("userinfo")) {
                const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
                this.formData.userId = userinfo.userId;
                this.formData.isTeacher = userinfo.isTeacher;
            } else if (this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''})) {
                this.formData.userId = this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''});
                this.formData.isTeacher = this.$cookies.get('app.isTeacher', {domain: banbaoUrl, path: ''});
            }
        },
        mounted() {
            this.getLevelList().then((res) => {
                this.getPersonInfo(res);
            })
        },
        methods: {
            getPersonInfo(levelList) {
                this.$http.get(`${urls.userServer.memberFrontFind}/${this.formData.userId}`).then(res => {
                    if (res.data.status === 200) {
                        this.formData = {
                            ...this.formData,
                            ...res.data.data,
                            provinceAndCity: (res.data.data.userPointer && res.data.data.userCity)
                                ? [res.data.data.userPointer, res.data.data.userCity]
                                : [],
                        };
                        if (levelList) {
                            levelList.map(item => {
                                if (item.id === res.data.data.levelId) {
                                    this.teacherLevel = item.name;
                                    return
                                }
                            })
                        }
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.warning(res.data.message);
                    }
                }).catch(err => {
                    console.log(err);
                })
            },
            chooseUploadFile: function(file, fileList, ref, refIsArray = true) {
                let that = this;
                // console.log(file);return ;

                const params = new FormData();
                params.append("file", file.raw);

                if(ref === 'headurl') {
                    let fileNameArr = file.name.split('.');
                    if(fileNameArr.length < 2 || !that.man.fast.inArray(fileNameArr[fileNameArr.length - 1].toLowerCase(), ['jpg', 'jpeg', 'png', 'gif'])) {
                        that.$message.error('不支持的格式');
                        return false;
                    }
                    if(file.size > 1024 * 1024) {
                        that.$message.error('文件大小不能超过1M');
                        return false;
                    }
                    that.$http.post(urls.fileHeadUpload, params).then((res) => {
                        // console.log(res.data);
                        if(res.data.status === 200) {
                            that.formData[ref] = res.data.data;
                        }
                    });
                } else {
                    let fileNameArr = file.name.split('.');
                    if(fileNameArr.length < 2 || !that.man.fast.inArray(fileNameArr[fileNameArr.length - 1].toLowerCase(), ['zip', 'rar', '7z'])) {
                        that.$message.error('不支持的格式');
                        return false;
                    }
                    if(file.size > 20 * 1024 * 1024) {
                        that.$message.error('文件大小不能超过20M');
                        return false;
                    }
                    that.accessoryName = file.name;
                    that.$http.post(urls.qualificationUpload, params).then((res) => {
                        // console.log(res.data);
                        if(res.data.status === 200) {
                            if(refIsArray) {
                                that.formData[ref].push({
                                    fileName: file.raw.name,
                                    fileUrl: res.data.data,
                                });
                            } else {
                                that.formData[ref] = res.data.data;
                            }
                        } else {
                            that.accessoryName = '';
                            that.$message.error(res.data.message)
                        }
                    }).catch((err) => {});
                }
            },
            getLevelList() {
                return new Promise((resolve, reject) => {
                    this.$http.get(urls.getTeacherLevel).then(res => {
                        if (res.data.status === 200) {
                            resolve(res.data.data);
                        } else {
                            this.$message.error(res.data.message);
                            reject(res.data.message);
                        }
                    }).catch(err => {
                        reject(err)
                    })
                })
            },
            selectedArea(e) { // 选择省市
                this.formData.userPointer = e.province.value;
                if (e.city && e.city.value) {
                    this.formData.userCity = e.city.value;
                }
            },
            entryMsg(e) { // 保存输入框信息
                const name = e.target.name;
                let value = e.target.value;
                this.$set(this.formData, name, value);
            },
            validate(name) { // 信息验证
                switch(name) {
                    case 'userContact':
                        if (!this.formData.userContact) {
                            this.$message.warning('请输入用户姓名');
                            return false
                        } else if (this.formData.userContact.length < 2) {
                            this.$message.warning('用户姓名长度需大于2个字符');
                            return false
                        } else {
                            return true
                        }
                        break;
                    case 'userPhone':
                        const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
                        if (!phoneReg.test(this.formData.userPhone)) {
                            this.$message.warning('请输入正确手机号码')
                            return false
                        } else {
                            return true
                        }
                        break;
                    case 'userMail':
                        const emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                        if (!emailReg.test(this.formData.userMail)) {
                            this.$message.warning('请输入正确的电子邮箱')
                            return false
                        } else {
                            return true
                        }
                        break;
                    case 'userPass':
                        const pwdReg = /^\w{6}/;
                        console.log(pwdReg.test(this.formData.userPass))
                        if (!pwdReg.test(this.formData.userPass)) {
                            this.$message.warning('密码至少是6位字母或数字')
                            return false
                        } else {
                            return true
                        }
                        break;
                    default:
                    break;
                }
            },
            submit() {
                // console.log(this.formData); return;
                // this.$refs.form.validate();
                if (!this.validate("userContact")) return
                if (!this.validate("userPhone")) return
                // if (!this.validate("password")) return
                if (this.formData.userMail !== '') {
                    if (!this.validate('userMail')) return
                }
                this.formData.userPointer = this.formData.provinceAndCity[0] ? this.formData.provinceAndCity[0] : '';
                this.formData.userCity = this.formData.provinceAndCity[1] ? this.formData.provinceAndCity[1] : '';

                let params = {...this.formData};
                delete params.provinceAndCity;

                // 教师
                this.$http.post(urls.edit, params).then(res => {
                    // console.log(res)
                    if (res.data.status === 200) {
                        this.$message.success('资料修改成功');
                        this.getPersonInfo();
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.warning(res.data.message)
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #personal-info {
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;

            >h1 {
                font-size: 1.25vw;
                margin: 1vw 0 1vw 2vw;
            }
            .form {
                width: 70%;
                box-sizing: border-box;
                margin: 1vw 0 1vw 2vw;
                border-radius: 10px;
                font-size: 0.83vw;
                .top,
                .teacher {
                    background: #fff;
                    padding: 1vw 2vw 0;
                    border-radius: 10px;
                    box-shadow: 0 5px 10px 1px rgba(170, 224, 240, 0.4);
                }
                .avatar {
                    display: flex;
                    align-items: center;
                    border-radius: 10px 10px 0 0;
                    img {
                        width: 5vw;
                        height: 5vw;
                        margin-right: 1vw;
                        cursor: pointer;
                        border-radius: 50%;
                    }
                    span {
                        color: #999;
                    }
                }
                .item {
                    display: flex;
                    justify-content: flex-start;
                    border-bottom: 1px solid #ddd;
                    padding: 1.5vw 0;
                    line-height: 200%;
                    .label {
                        width: 15%;
                        color: #999;
                    }
                    .text {
                        width: 80%;
                        textarea {
                            width: 100%;
                            resize: none;
                        }
                        input {
                            width: 100%;
                            /*padding: 0 5px;*/
                            color: #666;
                        }
                    }
                    @{deep} .error--text {
                        color: #ff5252 !important;
                        caret-color: #ff5252 !important;
                    }
                    .v-text-field {
                        padding-top: 0;
                        margin-top: 0;
                        font-size: 0.83vw;
                    }
                    @{deep} .city .distpicker-address-wrapper {
                        label:first-child {
                            display: none;
                        }
                    }
                    @{deep} .distpicker-address-wrapper {
                        position: relative;
                        &::after {
                            content: '';
                            position: absolute;
                            right: -2vw;
                            top: 12px;
                            border: 6px solid transparent;
                            border-top-color: #C6F2FF;
                        }
                        select {
                            font-size: 0.83vw;
                            border: 0;
                            width: 100%;
                            padding: 0;
                            height: auto;
                        }
                    }
                }
                .password {
                    border-radius: 0 0 10px 10px;
                    border-bottom: 0;
                }
                .teacher {
                    margin-top: 2vw;
                    border-radius: 10px;
                    .item {
                        &::after {
                            content: '';
                            display: block;
                            clear: both;
                        }
                        .filename {
                            float: left;
                            max-width: 70%;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            word-break: break-all;
                            white-space: nowrap;
                        }
                        .icon-license {
                            float: left;
                            width: 1.25vw;
                            height: 1.25vw;
                            background: url("../../assets/images/user-center/icon_license.png") no-repeat center center;
                            background-size: 100%;
                            margin: .3vw 10px 0 0;
                        }
                        label {
                            float: right;
                            cursor: pointer;
                            color: #02A5E6;
                            border: 1px solid #ddd;
                            border-radius: 50px;
                            padding: 0 1vw;
                        }
                        &:nth-child(2) {
                            border-bottom: 0;
                        }
                    }
                    .tips {
                        padding-left: 15%;
                        padding-bottom: 4%;
                        line-height: 200%;
                        font-size: 0.83vw;
                        h2 {
                            color: #DC000C;
                            margin-bottom: 0.5vw;
                        }
                        p {
                            color: #666;
                        }
                    }
                }
                .btn {
                    text-align: center;
                    font-size: 0.83vw;
                    color: #fff;
                    margin-top: 2vw;
                    button {
                        background-color: #24CEFF;
                        width: 30%;
                        padding: 1vw 0;
                        border-radius: 50px;
                    }
                }


                @{deep} .el-input__inner {
                    padding: 0;
                    border: unset;
                }
                @{deep} .el-input__suffix {display: none;}
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #personal-info {
            flex-direction: column;
            background: #F2FCFF;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                &>h1{
                    display: none;
                }
                .form{
                    width: 94.7%;
                    margin:0 auto;
                    
                    .fontFn(10,border-radius);
                    .top,
                    .teacher{
                        box-shadow: 0px 5px 10px #D5F5FF;
                        padding: 0 10px;
                        margin-bottom: 30px;
                    }
                    .avatar{
                        &>div{
                            width: 128px;
                        }
                        padding-top: 28px;
                        img{
                            width: 106px;
                            height: 106px;
                            margin-right: 0;
                        }
                        span{
                            .fontFn(14);
                            color:#A1A1A1;
                        }
                    }
                    .item{
                        padding: 30px 0;
                        .label{
                            .fontFn(16);
                            .fontFn(24,line-height);
                            color:#9A9A9A;
                            width: 128px;
                            flex:none;
                        }
                        .text{
                            flex:auto;
                            flex-grow: 1;
                            width: auto;
                            .fontFn(16);
                            input,
                            textarea{
                                color:#34254C;
                                .fontFn(16);
                                .fontFn(24,line-height);
                            }
                            @{deep} .el-cascader{
                                position: relative;
                                &::after{
                                    content:'';
                                    display: block;
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    border:10px solid #fff;
                                    border-top:16px solid #C6F2FF;
                                    right: 10px;
                                    top: 50%;
                                    margin-top:-8px;
                                }
                            }
                            @{deep} .el-input__inner{
                                .fontFn(16);
                            }
                        }
                    }
                    .teacher{
                        .item{
                            .icon-license{
                                .fontFn(24,width);
                                .fontFn(24,height);
                                margin:0 10px 0 0;
                            }
                            .filename{
                                max-width: none;
                                .fontFn(160,width);
                                font-weight: bold;
                                .fontFn(16);
                                .fontFn(24,line-height);
                            }
                            .uploadBtn{
                                float: none !important;
                                margin-top:16%;
                                label{
                                    .fontFn(16);
                                    padding: 0;
                                    .fontFn(126,width);
                                    .fontFn(36,height);
                                    .fontFn(36,line-height);
                                }
                            }
                        }
                        .tips{
                            padding:0;
                            padding-bottom: 30px;
                            h2{
                                .fontFn(14);
                                .fontFn(20,line-height);
                                margin-bottom: 10px;
                            }
                            p{
                                .fontFn(14);
                                .fontFn(20,line-height);
                                color: #555859;
                                padding-bottom: 3%;
                            }
                        }
                    }
                    .btn{
                        margin:0 0 50px;
                        button{
                            .fontFn(16);
                            width: 100%;
                            padding: 0;
                            .fontFn(54,height);
                            .fontFn(54,line-height);
                        }
                    }
                }
            }
        }
    }

</style>